<style scoped>
.rtsp {
  background-color: #666;
}
.canvas {
  width: 100%;
  height: 100%;
}
</style>

<template>
  <div class="rtsp" ref="root" :style="control.style">
    <canvas ref="canvas" class="canvas"></canvas>
  </div>
</template>

<script>
import client from "../utils/client";
export default {
  props: { control: Object },
  data() {
    return {
      player: null,
    };
  },
  mounted() {
    this.change();
  },
  beforeDestroy() {
    if (this.player) {
      try {
        this.player.destroy();
      } catch (e) {
        e.toString();
      }
      this.player = null;
    }
  },
  watch: {
    "control.config": {
      handler() {
        this.change();
      },
      deep: true,
    },
  },
  methods: {
    change() {
      if (this.player) {
        try {
          this.player.destroy();
        } catch (e) {
          e.toString();
        }
        this.player = null;
      }
      let rtsp = this.control.config.rtsp;
      if (rtsp) {
        let isMini = navigator.userAgent.toLowerCase().indexOf("miniprogram") != -1;
        let protocol = window.location.protocol == "http:" ? "ws:" : "wss:";
        let host = client.loginInfo.host;
        let port = client.loginInfo.port;
        let url;
        if (isMini) {
          url = `${protocol}//wx-ivisual.szzht.com/rtsp/${encodeURIComponent(
            rtsp
          )}/${host}/${port}`;
        } else if (port == 80) {
          url = `ws://${host}/rtsp/${encodeURIComponent(rtsp)}`;
        } else if (port == 443) {
          url = `wss://${host}/rtsp/${encodeURIComponent(rtsp)}`;
        } else {
          url = `${protocol}//${host}:${port}/rtsp/${encodeURIComponent(rtsp)}`;
        }
        this.player = new window.JSMpeg.Player(url, {
          canvas: this.$refs.canvas,
          pauseWhenHidden: false,
          videoBufferSize: 1024 * 1024,
        });
      }
    },
  },
};
</script>
